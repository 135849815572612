import { AfterViewInit, Component, Injectable, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { BehaviorSubject, from, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ModalPage } from 'src/app/pages/modal/modal.page';
import { AddtocartService } from 'src/app/services/addtocart.service';
import { HttpClient } from '@angular/common/http';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonUtils } from 'src/app/services/common-utils/common-utils';
import { map, tap } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss'],
})
export class CommonHeaderComponent implements OnInit, AfterViewInit {
  @Input() showAddtoCartReturnData: any;
  @Input() showWishlistReturnData: any;
  file_URL = environment.fileUrl;

  private _globalparamsData = new BehaviorSubject(null);
  private openwishlistDataSubscribe: Subscription; 
  private showAddtoCartDataSubscribe: Subscription; 
  private logoutDataSubscribe: Subscription; 
  private userDataSubscribe: Subscription;
  private showWishlistDataSubscribe: Subscription;
  private searchSubscribe: Subscription;
  
  totalItem: any;
  totalWishlist: any;
  openwishlistLoadData: any;
  openwishlistReturnData: any;
  openwishlist_Url;
  showAddtoCart_Url;
  showAddtoCartLoadData: any;
  showWishlist_Url;
  showWishlistLoadData: any;
  userinfo_Url;
  get_user_dtls;
  loginOrNot: any;
  getlogoLoadData;
  getlogo_Url;
  getlogoReturnData: any;
  searchUrl: string;
  productType: any;
  identifier: string;
  item: any;
  model:any ={};

  constructor(
    private modalController : ModalController,
    private storage: Storage, 
    private addtocartService: AddtocartService,
    private http : HttpClient,
    private router:Router,
    private authService: AuthService,
    private commonUtils : CommonUtils,
    private alertController : AlertController,
  ) { }

  ngOnInit() {
    this.getlogo();
    this.logoutDataSubscribe = this.authService.globalparamsData.subscribe(res => {
      if(res != null || res != undefined){
        this.get_user_dtls = res;
        this.commonUtils.onSigninStudentInfo(res.user);
      }
    });
    this.headerShowCartCount();
    this.autoLoginx();
    }  
 
    autoLoginx(){
      // stroage data get
      return from(this.storage.get('setStroageGlobalParamsData')).pipe(
        map(storData => {
          // console.log('storData @@@@@@@>>>>>', storData);
        }),
      );
    }

    ionViewWillEnter() {
      this.headerShowCartCount();
    }
    getlogo(){
      this.getlogoLoadData = true;
      this.getlogo_Url = `user/site-info`;
      this.http.get(this.getlogo_Url).subscribe(
        (res: any) => {
          this.getlogoReturnData = res.return_data;
          this.getlogoLoadData = false;
        },
        (err: any) => {
          this.getlogoLoadData = false;
        }
      );
    }

    // Search form Start
    onSubmitSearch(form: NgForm){
      this.productType = form.value.search;
      if(form.value.search == null){
        return false;
      }
      else{
        this.productSearch();
      }
    }
    // Search form End,

    //----------search Product Start-----------
  productSearch(){
    this.searchUrl = 'user/search-product/'+this.productType;
    this.searchSubscribe = this.http.get(this.searchUrl).subscribe(
      (res: any) => {
        if(res.return_status > 0){
          this.router.navigateByUrl(`product-list` + '/' + '0' + '/' + this.productType);
          // this.commonUtils.presentToast('success', res.return_message);
        }
        else{
          this.noproductModal();
          // this.commonUtils.presentToast('error', res.return_message);
        }
      },
      errRes => {
        // this.commonUtils.presentToast('error', errRes.return_message);
      }
    );
  }
  //------------Search Product End---------------

  async noproductModal(){
    // this.identifier = "Search";
    const alert = await this.alertController.create({
      header: 'Search ....!',
      message: "<img class='wish_login' src='assets/images/warning.gif'><div class='wish_text_blank'>Sorry, no results found!</div><div class='wish_text_blank2'>**Please check the spelling or try searching for something else</div>",
      buttons: [
        {
          text: '',
          role: 'Ok',
          cssClass: 'popup-cancel-btn',
          handler: (blah) => {
         }
        },
      ]
    });
    await alert.present();
  }
    
  // ..... Login  modal start ......
  async loginOpenModal(_identifier, _item, _items) {
    let changePassword_modal;
    changePassword_modal = await this.modalController.create({
      component: ModalPage,
      cssClass: 'mymodalClass small login_Modal',
      componentProps: { 
        identifier: _identifier,
        modalForm_item: _item,
        modalForm_array: _items
      }
    });
    
    // modal data back to Component
    changePassword_modal.onDidDismiss()
    .then((getdata) => {
      if(getdata.data == 'submitClose'){
        
      }

    });

    return await changePassword_modal.present();
  }
  // Login modal end 
  //logout page Start
  logout()
  {
    this.authService.logout();
    window.location.reload(); //working
  }
  //logout page Start
  // ..... register  modal start ......
  async registerOpenModal(_identifier, _item, _items) {
      let changePassword_modal;
      changePassword_modal = await this.modalController.create({
        component: ModalPage,
        cssClass: 'mymodalClass small register_Modal',
        componentProps: { 
          identifier: _identifier,
          modalForm_item: _item,
          modalForm_array: _items
        }
      });
      
      // modal data back to Component
      changePassword_modal.onDidDismiss()
      .then((getdata) => {
        if(getdata.data == 'submitClose'){
        }
      });
  
      return await changePassword_modal.present();
  }
  // register modal end 

  // ..... wishlist  modal start ......
  async wishlistOpenModal(_identifier, _item, _items) {
    this.storage.get('setStroageGlobalParamsData').then(async data => {
      this.loginOrNot = data;
      if (this.loginOrNot == null) {
        const alert = await this.alertController.create({
          header: 'Login ....!',
          message: "<img class='wish_login' src='assets/images/warning.gif'><div class='wish_text_blank'>You Are Not Login...</div><div class='wish_text_blank2'>**Please Login ☞</div>",
          buttons: [
            {
              text: 'Login',
              role: 'Ok',
              cssClass: 'popup-cancel-btn not-login-btn',
              handler: async (blah) => {
                let changePassword_modal;
                changePassword_modal = await this.modalController.create({
                  component: ModalPage,
                  cssClass: 'mymodalClass small login_Modal bg-gray-100',
                  componentProps: { 
                    identifier: 'login_modal',
                    modalForm_item: '',
                    modalForm_array: ''
                  }
                });
                // modal data back to Component
                changePassword_modal.onDidDismiss()
                .then((getdata) => {
                  // console.log("wishlistOpenModal getdata",getdata);
                  
                  // if(getdata.data == "close-modal" || getdata.role == "backdrop"){
                  //   // this.loginwishlistCount();
                  //  }
                });
                return await changePassword_modal.present();
              }
            },
          ]
        });
        await alert.present();
      } else {
          if(this.showWishlistReturnData == "0"){
            const alert = await this.alertController.create({
              header: 'Wishlist ...!',
              message: "<img class='cart_img_blank' src='assets/images/shopping-cart.gif'><div class='cart_text_blank'>Wishlist Is Blank...</div><div class='cart_text_blank2'>**Please Add a product...</div>",
              buttons: [
                {
                  text: '',
                  role: 'Ok',
                  cssClass: 'popup-cancel-btn',
                  handler: (blah) => {
                }
                },
              ]
            });
            await alert.present();
          }else{
          let changePassword_modal;
          changePassword_modal = await this.modalController.create({
            component: ModalPage,
            cssClass: 'mymodalClass medium wishlist_Modal',
            componentProps: { 
              identifier: _identifier,
              modalForm_item: _item,
              modalForm_array: _items
            }
          });
          
          // modal data back to Component
          changePassword_modal.onDidDismiss()
          .then((getdata) => {
            if(getdata.data == "close-modal" || getdata.role == "backdrop"){
              this.loginwishlistCount();
             }
          });
          return await changePassword_modal.present();
        }
      }
    });

   
  }
  // wishlist modal end 

     // ..... cart  modal start ......
      async cartOpenModal(_identifier, _item, _items) {
          if(this.showAddtoCartReturnData == "0"){
          const alert = await this.alertController.create({
            header: 'Cart ...!',
            message: "<img class='cart_img_blank' src='assets/images/shopping-cart.gif'><div class='cart_text_blank'>Cart Is Blank...</div><div class='cart_text_blank2'>**Please Add a product...</div>",
            buttons: [
              {
                text: '',
                role: 'Ok',
                cssClass: 'popup-cancel-btn',
                handler: (blah) => {
                  // console.log('Confirm Cancel: blah');
               }
              },
            ]
          });
          await alert.present();
        }
        else{
          let changePassword_modal;
          changePassword_modal = await this.modalController.create({
            component: ModalPage,
            cssClass: 'mymodalClass medium cart_Modal',
            componentProps: { 
              identifier: _identifier,
              modalForm_item: _item,
              modalForm_array: _items
            }
          });
          
          // modal data back to Component
          changePassword_modal.onDidDismiss()
          .then((getdata) => {
           if(getdata.data == "close-modal" || getdata.role == "backdrop"){
            this.headerShowCartCount();
           }
          });
          return await changePassword_modal.present();
        }
      }
    //Show AddtoCart
    headerShowCartCount(){
      this.storage.get('setStroageGlobalParamsData').then(data => {
        this.loginOrNot = data;
        if (this.loginOrNot == null) {
          this.tempCartCount();
        } else {
          this.logintempCartCount();
          this.loginwishlistCount();      
        }
      });
    }
    tempCartCount() {
      this.showAddtoCart_Url="user/product/tempcartcount";
        this.showAddtoCartLoadData = true;
        this.showAddtoCartDataSubscribe = this.http.get(this.showAddtoCart_Url).subscribe(
        (res:any) => {
          this.showAddtoCartLoadData = false;
          this.showAddtoCartReturnData = res.return_data;
          return this.showAddtoCartReturnData;
        },
        errRes => {
          this.showAddtoCartLoadData = false;
        }
      );
    } 

    logintempCartCount(){
      this.showAddtoCart_Url="user/login/home_hader";
        this.showAddtoCartLoadData = true;
        this.showAddtoCartDataSubscribe = this.http.get(this.showAddtoCart_Url).subscribe(
        (res:any) => {
          if(res.return_status > 0)
          {
            this.showAddtoCartLoadData = false;
            this.showAddtoCartReturnData = res.return_data.cartData;
          }else{
            this.showAddtoCartLoadData = false;
          }
        },
        errRes => {
          this.showAddtoCartLoadData = false;
        }
      );
    } 
    //Show AddtoCart

    // header wishlist show count 

    loginwishlistCount(){
      this.showWishlist_Url="user/login/home_hader";
        this.showWishlistLoadData = true;
        this.showWishlistDataSubscribe = this.http.get(this.showWishlist_Url).subscribe(
        (res:any) => {
          if(res.return_status > 0)
          {
            this.showWishlistLoadData = false;
            this.showWishlistReturnData = res.return_data.wishlistData;
          }else{
            this.showWishlistLoadData = false;
          }
        },
        errRes => {
          this.showWishlistLoadData = false;
        }
      );
    }

    // header wishlist show count 

  profileEdit(){
    this.router.navigateByUrl(`profile`);
  }
  ngOnDestroy(){
    if (this.openwishlistDataSubscribe !== undefined) {
      this.openwishlistDataSubscribe.unsubscribe();
    }
    if (this.showAddtoCartDataSubscribe !== undefined) {
      this.showAddtoCartDataSubscribe.unsubscribe();
    }
    if (this.logoutDataSubscribe !== undefined) {
      this.logoutDataSubscribe.unsubscribe();
    }
    if (this.userDataSubscribe !== undefined) {
      this.userDataSubscribe.unsubscribe();
    }
    if (this.showWishlistDataSubscribe !== undefined) {
      this.showWishlistDataSubscribe.unsubscribe();
    }
    if (this.searchSubscribe !== undefined) {
      this.searchSubscribe.unsubscribe();
    }
    
  }
  ngAfterViewInit(): void {
    
  }
    

}
