// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiMaster:'888',
  apiKey:'qwertyuiop',
// 
  // apiUrl:'http://192.168.1.17/hmshopping/hmshopping/api',
  // fileUrl:'http://192.168.1.17/hmshopping/hmshopping/'

  // apiUrl:'https://d462-2401-4900-1c00-31a-2d7f-dde5-204a-8cc.in.ngrok.io/laravel_project/hmshopping/api',
  // fileUrl:'https://d462-2401-4900-1c00-31a-2d7f-dde5-204a-8cc.in.ngrok.io/laravel_project/hmshopping/'

  // apiUrl:'http://192.168.29.18/HMShp/hm/hmshopping/api',
  // fileUrl:'http://192.168.29.18/HMShp/hm/hmshopping/'
  
  // apiUrl:'http://192.168.1.2/hmshopping_backend/api',
  // fileUrl:'http://192.168.1.2/hmshopping_backend/'
  
  apiUrl:'https://hmshopping.in/superadmin/api',
  fileUrl:'https://hmshopping.in/superadmin/'

  // apiUrl:'http://localhost/hmshopping/api',
  // fileUrl:'http://localhost/hmshopping/'  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
