import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpResponse, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { tap, shareReplay, retry, map, catchError, switchMap } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../environments/environment';
import { CommonUtils } from '../common-utils/common-utils';

const API_URL = environment.apiUrl;
const API_MASTER = environment.apiMaster;
const API_KEY = environment.apiKey;

/* tslint:disable */ 

@Injectable()
export class InterceptorProvider implements HttpInterceptor {
  isparams = false;

  constructor(
    private router: Router,
    public toastController: ToastController,
    // private storage: Storage,
    private authService : AuthService,
    private commonUtils: CommonUtils
) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Keeps the original request params. as a new HttpParams
    let appKey;
    let authorization;
    let newParams = new HttpParams({fromString: request.params.toString()});

    let get_global_params = this.authService.getTokenSessionMaster();
    if(get_global_params != null && get_global_params.master == undefined){
      get_global_params.master = API_MASTER;
    }
    if(get_global_params != null && get_global_params.token !== undefined &&get_global_params.session !== undefined && get_global_params.master !== undefined){

    }
    
    if(get_global_params == null || get_global_params.token == null){
      appKey = API_KEY;
      authorization = 'Bearer'
    }else {
      appKey = API_KEY;
      authorization = 'Bearer '+get_global_params.token;
    }

    if(appKey) {
      const requestClone = request.clone({
        url: `${API_URL}/${request.url}`,
        setHeaders: {
          'APP-KEY': appKey,
          'Authorization': authorization,
        }
      });
    return next.handle(requestClone).pipe(
      map((event: HttpEvent<any>) => {
        let eventUrl;
        if (event instanceof HttpResponse) {
          if(event.body.return_status == 0){
            this.commonUtils.presentToast('error', event.body.return_message);
          }

          //token expire check
          if(event.body.return_token_expire){
            this.authService.logout();
          }

          //show return_message
          if(!event.body.return_token_expire){
            // this.commonUtils.presentToast('info', event.body.return_message);
          }

        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0) {
          this.commonUtils.presentToast('error', 'Please Check Your Network Connection!');
        }else if(error.status === 404){
          this.commonUtils.presentToast('error', 'An error occurred result page not found, please try again');
        }else if(error.status === 500){
          this.commonUtils.presentToast('error', 'Internal Server Error');
        }else if(error.status === 401){
          this.commonUtils.presentToast('error', 'Could not sign you up, please try again');
          this.authService.logout();
        }
        return throwError(error);
      }));
  }
  }
}

  
