import { Component, Renderer2, Inject, OnInit, ViewChildren } from '@angular/core';
import { Platform, NavController, IonRouterOutlet, AlertController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import {  MenuController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Subscription, observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { AuthService } from './services/auth/auth.service';
import { CommonUtils } from './services/common-utils/common-utils';

import { environment } from '../environments/environment';
import { DOCUMENT } from '@angular/common';
import { Spinkit } from 'ng-http-loader';

/* tslint:disable */ 
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  @ViewChildren(IonRouterOutlet) routerOutlets;
  main_url = environment.apiUrl;
  file_url = environment.fileUrl;
  public spinnerStyle = Spinkit;
  // public spinkit = Spinkit;

  // variable define
  url_name;
  url_path_name;
  get_user_type;
  panelOpenState: boolean;
  userInfodDataLoading;
  private userInfoSubscribe: Subscription;
  private groupMenuDataSubscribe : Subscription;
  private userDataSubscribe : Subscription;
  menuPages = [];
  menuPagesList;
  menuPages2 = [];
  activeMenuHilight;
  selectedItemActive;
  parentSelectedIndex;
  childSelectedIndex;
  siteInfo : any;
  isActive : boolean = false;
  siteInfoLoading;
  checkAuthentication;
  headerData;
  opacity: number=1;

  constructor(
    private platform: Platform,
    private activatedRoute : ActivatedRoute,
    private http : HttpClient,
    private authService : AuthService,
    private menuCtrl: MenuController,
    private renderer: Renderer2,
    private router : Router,
    private navCtrl : NavController,
    private alertController: AlertController,
    private commonUtils: CommonUtils, // common functionlity come here
    @Inject(DOCUMENT) private _document: HTMLDocument //use for fabicon
  ) {
    
    this.authService.autoLogin().pipe(
      take(1)
    ).subscribe(resData => {
      if(resData){
        this.checkAuthentication = true;
        this.initializeApp();
        this.onSiteInformation();
        // this.userDataGet();
      }else{
        this.checkAuthentication = false;
        this.onSiteInformation();
      }
    });
    
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(){
    this.backButtonEvent(); 
  }

  backButtonEvent() {
    this.platform.backButton.subscribe(() => {
        if (this.router.url === '/home') {
          this.presentAlertConfirm();
        } else {
          window.history.back();
        }
      // });
    });
  }

  // alert call
  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: 'Exit App',
      message: 'Are you sure you want to exit?',
      cssClass: 'custom-alert2',
      buttons: [{
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'cancelBtn',
        handler: (blah) => { }
      }, {
        text: 'Close App',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    });
    await alert.present();
  }

  onResize(e){
    // this.responsiveService.checkWidth();
  }

  initializeApp() {
    this.platform.ready().then(() => {

      // user data call
      this.userInfoData();
      
      // ----get current active url name start---
        this.activatedRoute.url.subscribe(activeUrl => {
          this.url_name = window.location.pathname;
        })
        
      //get current active url name end

      // observable data for all page url name get
      this.commonUtils.pagePathNameAnywhereObsv.subscribe(pathRes => {
        this.url_path_name = pathRes;
      });

    });
  }
  //------------------- menu item show start------------------------

  

  // menu data call
  mapped;
  userInfoData(){

    this.menuPages = [];
    this.userInfodDataLoading = false;

    this.commonUtils.getSiteInfoObservable
    .pipe(
      take(1)
    ).subscribe(res => {
      this.headerData = res;

      this.menuPages = [];


      const parsedUrl = new URL(window.location.href);
      const baseUrl = parsedUrl.hostname;
      console.log('baseUrl> ', baseUrl); // this will print http://example.com or http://localhost:4200
      if(baseUrl == 'localhost' || baseUrl == '192.168.0.10'){
        this.site_url_name = 'https://www.online-restaurent.bongtechsolutions.com/#/home';
      }else{
        this.site_url_name = window.location.href;
      }
      
    });
  }

   // ============site information get start =============
   site_path;
   site_href;
   site_href_split;
   site_path_split;
   site_url_name;
   onSiteInformation(){
 
     this.site_path = window.location.pathname;
     this.site_href = window.location.href;
     this.site_href_split = window.location.href.split('/')[1];
     this.site_path_split = window.location.pathname.split('/')[1];
 
     // server print reasult///////
     /* site_path > /ci/xcelero/online/ 
     site_href > https://www.online-restaurent.bongtechsolutions.com/#/home 
     site_href_split > 
     site_path_split > ci  */
 
     const parsedUrl = new URL(window.location.href);
     const baseUrl = parsedUrl.hostname;
     console.log('baseUrl> ', baseUrl); // this will print http://example.com or http://localhost:4200
     if(baseUrl == 'localhost' || baseUrl == '192.168.0.10'){
       this.site_url_name = 'https://www.online-restaurent.bongtechsolutions.com/#/home';
     }else{
       this.site_url_name = window.location.href;
     }
     this.siteInfoLoading = true;
   }

  //  page go
  addClass: boolean = false;
  goToPage(_url, _item){

    this.navCtrl.navigateRoot(_url);
  }

}
