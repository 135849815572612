import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ModalController } from '@ionic/angular';
import { ModalPage } from 'src/app/pages/modal/modal.page';

@Component({
  selector: 'common-footer',
  templateUrl: './common-footer.component.html',
  styleUrls: ['./common-footer.component.scss'],
})
export class CommonFooterComponent implements OnInit {
  file_URL = environment.fileUrl;
  getlogoLoadData;
  getlogo_Url;
  getlogoReturnData: any;
  return_social: any;

  constructor(
    private http : HttpClient,
    private modalController : ModalController,
  ) { }

  ngOnInit() {
    this.getlogo();
  }
  getlogo(){
    this.getlogoLoadData = true;
    this.getlogo_Url = `user/site-info`;
    this.http.get(this.getlogo_Url).subscribe(
      (res: any) => {
        this.getlogoReturnData = res.return_data;
        this.return_social = res.return_data.social;
        this.getlogoLoadData = false;
      },
      (err: any) => {
        this.getlogoLoadData = false;
      }
    );
  }
}
